import StoreService from "@/api/services/store";
import AdminService from "@/api/services/admin";

const initialState = {
  availableFilters: {
    status: [],
    stores: [],
  },
  selectedFilters: {
    status: [],
    stores: [],
  },
  claims: [],
  totalNumberOfClaims: null,
  keywords: "",
  selectedStoreId: null,
};

export default {
  namespaced: true,

  state: initialState,

  getters: {
    getClaims: (state) => {
      return state.claims;
    },
  },

  mutations: {
    setClaims(state, { claims, totalNumberOfClaims }) {
      state.claims = claims;
      state.totalNumberOfClaims = totalNumberOfClaims;
    },

    setFilter(state, { filterName, filterOptions }) {
      state.selectedFilters[filterName] = filterOptions;
    },

    setKeywords(state, keywords) {
      state.keywords = keywords;
    },

    setAvailableFilters(state, { status, stores }) {
      state.availableFilters.status = status;
      state.availableFilters.stores = stores;
    },

    setSelectedStoreId(state, selectedStoreId) {
      state.selectedStoreId = selectedStoreId;
    },
  },

  actions: {
    async fetchClaims({ state, commit, rootGetters }, { page }) {
      const getClaimsFunction = rootGetters["navigation/isAdminPage"] ? AdminService.getClaims : StoreService.getClaims;
      let response = null;

      try {
        response = await getClaimsFunction({
          storeId: state.selectedStoreId,
          data: {
            page: page,
            stores: state.selectedFilters.stores,
            status: state.selectedFilters.status,
            keywords: state.keywords,
          },
        });

        commit("setClaims", {
          claims: response.data.claims,
          totalNumberOfClaims: response.data.totalNumberOfClaims,
        });

        commit("setAvailableFilters", {
          status: response.data.filters.status,
          stores: response.data.filters.stores,
        });
      } catch (error) {
        console.error(error);
        return;
      }
    },

    updateSearch({ commit, dispatch }, { searchString, page }) {
      commit("setKeywords", searchString);
      dispatch("fetchClaims", {
        page: page,
      });
    },

    updateFilter({ commit, dispatch }, { filterName, filterOptions, page }) {
      commit("setFilter", { filterName, filterOptions });
      dispatch("fetchClaims", {
        page: page,
      });
    },

    restrictStore({ commit }, storeId) {
      commit("setSelectedStoreId", storeId);
    },
  },
};
