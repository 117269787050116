<template>
  <div>
    <h1 class="text-4xl font-heading-1 pl-2 tracking-tight text-gray-900 my-4">{{ $t("Invoices") }}</h1>
    <InvoicesSummary
      v-bind:amount-owed-to-estaly="this.amountOwedToEstaly"
      v-bind:amount-owed-to-subject="this.amountOwedToSubject"
    />
    <div class="my-7 flex w-full gap-10 items-center">
      <MonthlyDateFilterSelector
        class="w-1/4"
        placeholder="Sélectionner une période"
        @newDateSelected="updateDateFilters"
      />
      <SearchBarFilter
        v-for="(filterCategory, filtersName, filterIndex) in availableFiltersWithOptions"
        v-bind:key="filterIndex"
        v-bind:filterName="filtersName"
        v-bind:filterIdx="filterIndex"
        v-bind:current-page="currentPage"
        v-bind:available-filters="filters"
        v-bind:selected-filters="currentFilteringData"
        @updateFilterSearch="this.updateCurrentFilteringData"
      />
    </div>
    <div>
      <div
        class="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700"
      >
        <ul class="flex flex-wrap -mb-px" data-tabs-toggle="#myTabContent">
          <li class="mr-2" id="no-invoice-flow">
            <button
              @click="this.noInvoiceFlow"
              :class="[
                this.activeTab === 1
                  ? 'text-primary-dark border-primary-dark active'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300',
                'inline-block p-4 border-b-2 rounded-t-lg',
              ]"
            >
              Tout
            </button>
          </li>
          <li class="mr-2" id="subject-invoice-flow">
            <button
              @click="this.toSubjectInvoiceFlow"
              :class="[
                this.activeTab === 3
                  ? 'text-primary-dark border-primary-dark active'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300',
                'inline-block p-4 border-b-2 rounded-t-lg',
              ]"
            >
              Entrant
            </button>
          </li>
          <li class="mr-2" id="estaly-invoice-flow">
            <button
              @click="this.toEstalyInvoiceFlow"
              :class="[
                this.activeTab === 2
                  ? 'text-primary-dark border-primary-dark active'
                  : 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300',
                'inline-block p-4 border-b-2 rounded-t-lg',
              ]"
            >
              Sortant
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <InvoicesContent v-bind:invoices="this.invoices" />
      <Pagination
        v-bind:currentPage="currentPage"
        v-bind:pages="pages"
        v-bind:startIdx="startIdx"
        v-bind:endIdx="endIdx"
        v-bind:total="this.totalNumberOfInvoices"
        v-on:changePage="this.handleChangePage"
        v-if="pages.length > 1"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/utils/Pagination.vue";
import AdminService from "../../../api/services/admin";
import InvoicesSummary from "@/views/admin/invoice/InvoicesSummary.vue";
import InvoicesContent from "@/views/admin/invoice/InvoicesContent.vue";
import SearchBarFilter from "@/components/menu/SearchBarFilter.vue";
import MonthlyDateFilterSelector from "@/components/menu/MonthlyDateFilterSelector.vue";

const PAGE_SIZE = 25;

export default {
  components: {
    InvoicesContent,
    InvoicesSummary,
    Pagination,
    SearchBarFilter,
    MonthlyDateFilterSelector,
  },

  computed: {
    pages: function () {
      if (this.totalNumberOfInvoices < PAGE_SIZE) {
        return [];
      }
      return [...Array(Math.ceil(this.totalNumberOfInvoices / PAGE_SIZE)).keys()].map((e) => e + 1);
    },

    startIdx: function () {
      return (this.currentPage - 1) * PAGE_SIZE;
    },

    endIdx: function () {
      return Math.min(this.startIdx + PAGE_SIZE, this.totalNumberOfInvoices);
    },

    availableFiltersWithOptions: function () {
      return Object.fromEntries(
        Object.entries(this.filters).filter(([filterKey, filterOptions]) => filterKey && filterOptions?.length > 1)
      );
    },
  },

  data() {
    return {
      invoices: [],
      filters: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        invoice_flows: [],
      },
      totalNumberOfInvoices: 0,
      currentPage: 1,
      currentFilteringData: {
        organizations: [],
        stores: [],
        payment_statuses: [],
        invoice_flows: [],
        start_date: null,
        end_date: null,
      },
      invoicesFlows: [],
      activeTab: 1,
      amountOwedToEstaly: null,
      amountOwedToSubject: null,
    };
  },

  mounted() {
    this.retrieveInvoices();
  },

  methods: {
    handleChangePage: function (page) {
      this.currentPage = page;
      this.retrieveInvoices();
    },

    updateCurrentFilteringData: function (filterName, filterOptions) {
      this.currentFilteringData[filterName] = filterOptions;
      this.currentFilteringData["invoice_flows"] = this.invoicesFlows;
      this.handleChangePage(1);
    },

    updateDateFilters: function (startDate, endDate) {
      this.currentFilteringData["start_date"] = startDate;
      this.currentFilteringData["end_date"] = endDate;
      this.handleChangePage(1);
    },

    noInvoiceFlow: function () {
      this.invoicesFlows = [];
      this.activeTab = 1;
      this.updateCurrentFilteringData(this.currentFilteringData);
    },

    toSubjectInvoiceFlow: function () {
      this.invoicesFlows = ["to_subject"];
      this.activeTab = 3;
      this.updateCurrentFilteringData(this.currentFilteringData);
    },

    toEstalyInvoiceFlow: function () {
      this.invoicesFlows = ["to_estaly"];
      this.activeTab = 2;
      this.updateCurrentFilteringData(this.currentFilteringData);
    },

    retrieveInvoices: async function () {
      try {
        const response = await AdminService.getInvoices(this.currentPage, this.currentFilteringData);

        this.invoices = response.data.invoices;
        this.filters = response.data.filters;
        this.amountOwedToSubject = response.data.amountOwedByEstaly;
        this.amountOwedToEstaly = response.data.amountOwedToEstaly;

        this.totalNumberOfInvoices = response.data.totalNumberOfInvoices;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
